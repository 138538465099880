// import nodemailer from 'nodemailer';

// const sendEmail = async (formData, email) => {
//   const transporter = nodemailer.createTransport({
//     host: process.env.SMTP_HOST,
//     port: process.env.SMTP_PORT,
//     secure: false,
//     auth: {
//       user: process.env.SMTP_USERNAME,
//       pass: process.env.SMTP_PASSWORD
//     }
//   });

//   const mailOptions = {
//     from: process.env.EMAIL_ADDRESS,
//     to: process.env.RECIPIENT_EMAIL_ADDRESS,
//     subject: formData.subject,
//     text: `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${formData.message}`
//   };

//   try {
//     await transporter.sendMail(mailOptions);
//     console.log('Email sent successfully!');
//   } catch (error) {
//     console.error('Error sending email:', error);
//     throw new Error('An error occurred while sending the email.');
//   }
// };

// export default sendEmail;


import nodemailer from 'nodemailer';

const sendEmail = async (formData) => {
    try {
        const transporter = nodemailer.createTransport({
            host: process.env.SMTP_HOST,
            port: process.env.SMTP_PORT,
            secure: true, // Set to true if you're using a secure connection (SSL/TLS)
            auth: {
                user: process.env.SMTP_USERNAME,
                pass: process.env.SMTP_PASSWORD
            }
        });

        const mailOptions = {
            from: formData.EMAIL_ADDRESS,
            to: process.env.RECIPIENT_EMAIL_ADDRESS,
            subject: formData.subject,
            text: `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${formData.message}`
        };

        await transporter.sendMail(mailOptions);
        console.log('Email sent successfully!');
    } catch (error) {
        console.error('Error sending email:', error);
        throw new Error('An error occurred while sending the email.');
    }
};

export default sendEmail;
