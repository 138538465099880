import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "https://scontent.fyzd1-3.fna.fbcdn.net/v/t39.30808-6/440954905_302873022907556_8596705059491246347_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_ohc=s2RrT1oRAHMAb6jzRyB&_nc_ht=scontent.fyzd1-3.fna&oh=00_AfAp3Tplxu3glskEOloLaOAljor9UwqzbjO6Ee99MYegdA&oe=663416ED"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Our Team</span>
                        <h2>Collaboration Brings Greatness</h2>  
                        <p>At Oanwave, we believe in the power of collaboration and teamwork to achieve greatness. We understand that working together brings out the best in everyone, and this is why we encourage our team and clients to work together towards a common goal. With our combined strengths, we can accomplish much more than we ever could alone. Join us on our journey towards greatness through collaboration.</p>
                        <Link to="#" className="axil-btn btn-large btn-fill-primary">Our Team</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;