import React, {useRef, useState} from 'react';
import sendEmail from '../../common/sendEmail';
import Alert from 'react-bootstrap/Alert';
import emailjs from '@emailjs/browser';

const Result = () => {
     const form = useRef();
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}



const FormTwo = () => {
    const form = useRef();
    const [ result, showresult ] = useState(false);
    const [formData, setFormData] = useState({
        subject: '',
        name: '',
        email: '',
        phone: '',
        message: '',
      });

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };

      const { subject, name, email, phone, message } = formData;

      const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs.sendForm('service_570br0a', 'template_zl4ij31', form.current, 'vO_6qRjVSStWug5Wd')
            .then((result) => {
                console.log(result.text);
                alert('Email sent successfully!');
            }, (error) => {
                console.log(error.text);
            });
            showresult(true);
            setFormData({
                subject: '',
                name: '',
                email: '',
                phone: '',
                message: '',
              });
        };
  
          setTimeout(() => {
              showresult(false);
          }, 5000);

    //   const handleSubmit = (event) => {
    //     event.preventDefault();
    
    


    //   };

    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <div className="form-group">
            <label>Name</label>
            {/* <input type="text" className="form-control" name="contact-name" required /> */}
            <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
            <label>Email</label>
            {/* <input type="email" className="form-control" name="contact-email" required /> */}
            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
            <label>Phone</label>
            {/* <input type="tel" className="form-control" name="contact-phone" required /> */}
            <input type="tel" className="form-control" name="phone" value={formData.phone} onChange={handleChange} required />
        </div>
        <div className="form-group mb--40">
            <label>How can we help you?</label>
            {/* <textarea className="form-control" name="contact-message" rows="4"></textarea> */}
            <textarea className="form-control" name="message" value={formData.message} onChange={handleChange} rows="4"></textarea>
        </div>
        <div className="form-group">
            <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Send Now</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default FormTwo;

