import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        {/* <img src={process.env.PUBLIC_URL + "https://new.axilthemes.com/demo/react/abstrak/images/others/case-study-4.png"} alt="travel" /> */}
                        <img src={process.env.PUBLIC_URL + "https://scontent.fyzd1-3.fna.fbcdn.net/v/t39.30808-6/440767781_302876779573847_5857886205800382512_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_ohc=74VoEtJdK98Q7kNvgFfWUG3&_nc_ht=scontent.fyzd1-3.fna&oh=00_AfBIVPcaq5cWMAkJGklcwLTPJnW9QmZ0e2f2DuYhlsgSGQ&oe=663408AF"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Who we are</span>
                            <h2 className="title">Building software for world changers</h2>
                            <p>Oanwave is an IT Consulting and co-founding firm based in Toronto, Canada that specializes in building innovative software solutions for visionary entrepreneurs and organizations, empowering them to drive positive change and shape a better future.</p>
                            <p>At Oanwave, we believe that technology has the power to transform lives and create a better world. Our journey began with a simple yet bold idea: to harness the potential of software innovation to drive positive change. Today, we're a diverse team of experts from various fields, including software development, data science, and design, who work together to create cutting-edge technology that facilitates collaboration and innovation.</p>
                            <Link to="#" className="axil-btn btn-fill-primary btn-large">Read More</Link>
                        </div>
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="4" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Years on the market</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="15" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Projects delivered so far</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;